
.Chatscroll{
    transition-delay: 1s;
    transition-property: width;
}

.listScroll::-webkit-scrollbar {
    width:5px
}
.listScroll::-webkit-scrollbar-track{
    width:5px
}
.listScroll::-webkit-scrollbar-thumb{
    width:5px;
    background-color: #323259;

}

.Chatscroll::-webkit-scrollbar {
    display: none;
}
.css-12yjm75-MuiInputBase-input-MuiOutlinedInput-input:focus{
outline: none;
}

@keyframes dotAnimation {
    0% { content: '.'; }
    30% { content: '..'; }
    60% { content: '...'; }
    90%{content:'....'}
    100% { content: ''; }
}

.loaderwidth::after {
    content: '';
    animation: dotAnimation 1.5s steps(1, end) infinite;
}